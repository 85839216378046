import JsComponent from '../jsComponent/jsComponent.js';
import pigeon from '../../utilities/js/pigeon/pigeon.js';

class BurgerTrigger extends JsComponent {
  constructor(mainElement, name = 'burger-trigger') {
    super(mainElement, name);

    this.isOpen = false;
    this.bindEvents();
  }

  bindEvents() {
    pigeon.subscribe('burgerNavigation:stateChange', (val) => {
      this.isOpen = val;
    })

    this.mainElement.addEventListener('click', () => {
      pigeon.publish('burgerNavigation:open', !this.isOpen);
    });
  }
}

export default BurgerTrigger;
