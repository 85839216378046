enum ProductBoxImageType {
  Default,
  Transparent,
  DefaultRetina,
  TransparentRetina,
}

interface ProductBoxImage {
  src: string,
  alt: string,
  title: string
}


export {ProductBoxImage, ProductBoxImageType};
