<template>
  <div class="product-box-wide__segment-conversion">
    <div class="product-box-wide__wishlist-wrapper">
      <span
        class="product-box-wide__button-wishlist product-box__button-wishlist"
        :class="{
          'product-box__button-wishlist--filled': isWishlistEnabled && productBoxData.inWishlist,
          'product-box__button-wishlist--remove': !isWishlistEnabled,
          'product-box__button-wishlist--loading': state === 'loadingWishlist',
        }"
        @click.prevent="$emit('wishlistClicked')"
      />
    </div>
    <div
      class="product-box-wide__product-flags product-box-wide__product-flags--conversion"
      v-if="productBoxData.flags"
    >
      <flags
        :flags="productBoxData.flags"
        :limit="1"
        :white-list="settings.flags.whitelistConversion"
      />
    </div>
    <div
      v-if="bulkPrice"
      class="product-box__bulk-price"
    >
      <div
        class="product-box__bulk-price-info"
        v-html="bulkPrice"
        @click="$emit('quantityChange', bulkPriceQuantity)"
      />
    </div>
    <availability-info
      v-if="showAvailability"
      class="product-box-wide__availability product-box__availability"
      :availability="productBoxData.availability"
    />
    <winemaker-price
      v-if="productBoxData.winemakerPrice"
    />
    <div class="product-box-wide__price-wrapper">
      <price-info
        v-if="!hidePrice && productBoxData.price"
        class="product-box-wide__price product-box__price"
        :value="productBoxData.price"
        :alcohol="productBoxData.alcohol"
        :show-star="true"
      />
    </div>
    <div class="product-box__buttons-wrapper">
      <product-qty-and-cart-btn
        class="product-box-wide__buttons"
        :product-data="productBoxData"
        :product-link="productLink"
        :state="state"
        :add-successor-active="addSuccessorActive"
        @quantityChange="$emit('quantityChange', $event)"
        @addToBasket="$emit('addToBasket', $event)"
        @onRequestClicked="$emit('onRequestClicked', $event)"
      />
      <a
        class="product-box__status product-box__status--basket"
        v-if="productBoxData.inBasket"
        :href="basketUrl"
        v-t="'ads.ads_top_info.article_in_basket'"
      />
      <a
        class="product-box__status product-box__status--basket product-box__status--successor-basket"
        v-if="productBoxData.successorInBasket && !productBoxData.inBasket && addSuccessorActive"
        :href="basketUrl"
        v-t="'ads.ads_top_info.successor_in_basket'"
      />
      <a
        v-if="productBoxData.showFoodSpecificationLink && !hideCounterButton"
        :href="foodSpecificationLink"
        class="product-box__link"
        v-html="$t('detail.product.characteristics')"
      />
    </div>
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import AvailabilityInfo from '../../../bps/components/availabilityInfo/availabilityInfo.vue'
  import ProductQtyAndCartBtn from '../../../bps/components/productQtyAndCartBtn/productQtyAndCartBtn.vue';
  import Flags from '../../../bps/components/flags/flags.vue'
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import Lightbox from '../../../bps/components/lightbox/lightbox.vue';
  import {ProductBoxWideSettings} from './productBoxWideSettings';
  import WinemakerPrice from '../../../bps/components/winemakerPrice/winemakerPrice.vue';
  import Tag from '../../../bps/types/Tag';
  import BulkPrice from '../../../bps/types/BulkPrice';

  @Options({
    name: 'product-box-wide-segment-conversion',
    components: {
      AvailabilityInfo,
      ProductQtyAndCartBtn,
      Flags,
      Lightbox,
      WinemakerPrice
    }
  })
  export default class ProductBoxWideSectionConversion extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxWideSettings;
    @Prop()
    basketUrl: string;
    @Prop()
    minimumRating: string;
    @Prop({default: true})
    isWishlistEnabled: boolean;
    @Prop()
    state: string;
    @Prop()
    foodSpecificationLink: string;
    @Prop()
    showAvailability: boolean;
    @Prop()
    hideCounterButton: boolean;
    @Prop({default: false})
    hidePrice: boolean;
    @Prop({default: false})
    addSuccessorActive: boolean;
    @Prop()
    productLink: string;

    get bulkPriceQuantity() {
      const bulkTag: Array<Tag> = this.productBoxData.tags.filter(tag => tag.type === 'BULK_PRICE');
      if (bulkTag?.length > 0) {
        const info = bulkTag[0].info as BulkPrice;
        return info.quantity;
      }
      return null;
    }

    get bulkPrice() {
      const bulkTag: Array<Tag> = this.productBoxData.tags.filter(tag => tag.type === 'BULK_PRICE');
      if (bulkTag?.length > 0) {
        const info = bulkTag[0].info as BulkPrice;
        return info.price ? this.$t('tags.bulkPrice', [info.quantity, info.price]) : this.$t('tags.bulkPrice.noPrice', [info.quantity]);
      }
      return null;
    }
  }
</script>
