import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-name__bottles"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productClickable ? 'a' : 'span'), {
    href: _ctx.productClickable ? _ctx.product.url || _ctx.product.productUrl : null,
    class: "product-name"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.product.name) + " ", 1 /* TEXT */),
      (_ctx.product.numberOfBottlesInSet && _ctx.product.numberOfBottlesInSet > 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('product.name.bottlesInSet', [_ctx.product.numberOfBottlesInSet])), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href"]))
}