<template>
  <transition name="localisation-switch-layer-animate-top">
    <form
      class="localisation-switch-layer"
      v-if="isOpen"
      method="get"
      action=""
      v-outside-click:[true]="close"
    >
      <div class="localisation-switch-layer__head">
        {{ $t('localisation.layer.headline') }}
        <div
          class="localisation-switch-layer__close"
          @click="close()"
        />
      </div>
      <div class="localisation-switch-layer__content">
        <select-vue
          class="localisation-switch-layer__country-select"
          v-model="model.country"
          :options="getCountryOptions()"
          :initial-value="selectedCountry"
        />
        <select-vue
          v-if="getLanguageOptions().length > 1"
          class="localisation-switch-layer__language-select"
          v-model="model.language"
          :options="getLanguageOptions()"
          :initial-value="model.language"
        />
        <input
          type="hidden"
          name="geo"
          :value="getSubmitValue(model.country)"
        >
        <input
          type="hidden"
          name="selectedCountry"
          :value="model.country"
        >
        <input
          v-if="getLanguageOptions().length > 1"
          type="hidden"
          name="lang"
          :value="getSubmitValue(model.language)"
        >
        <button
          class="localisation-switch-layer__btn | button button--primary"
          :class="{'button--loading': isLoading}"
          type="submit"
        >
          {{ $t('localisation.layer.button.save') }}
        </button>
      </div>
    </form>
  </transition>
</template>

<script lang="ts">
  import {Vue, Prop, Options} from 'vue-property-decorator';
  import pigeon from '../../utilities/js/pigeon/pigeon';
  import SelectVue from '../select/select.vue';
  import jsGlobalConfig from '../../globals/jsGlobalConfig';

  const globalConfig = jsGlobalConfig();

  @Options({
    name: 'localisation-switch-layer',
    components: {
      SelectVue
    }
  })
  export default class LocalisationSwitchLayer extends Vue {
    @Prop({type: String})
    selectedCountry: String;

    isOpen = false;
    isLoading = false;

    model = {
      country: '',
      language: '',
    }

    mounted() {
      pigeon.subscribe('localisationSwitch:open', this.open);
    }

    open() {
      this.isOpen = true;
      pigeon.publish('pageoverlay:show', 'page-overlay--visible-transparent');
    }

    close() {
      this.isOpen = false;
      pigeon.publish('pageoverlay:hide', 'page-overlay--visible-transparent');
    }

    getSubmitValue(val: string) {
      return this.$t(`localisation.layer.value.country.${val}`).toString();
    }

    getCountryOptions() {
      const options = ['de', 'ch', 'at', 'global'];

      return options.map((key) => {
        return {
          label: this.$t(`localisation.layer.country.${key}`),
          value: key,
          icon: `<img src="${globalConfig.getAssetPath()}/frontend-core/staticImages/country-${key}.svg" alt="${key}" class="localisation-button__flag" />`
        }
      });
    }

    getLanguageOptions() {
      const options = ['de'];

      return options.map((key) => {
        return {
          label: this.$t(`localisation.layer.laguage.${key}`),
          value: key
        }
      });
    }
  }
</script>
