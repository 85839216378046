<template>
  <div
    class="price-info"
    :class="{
      'price-info--reduction': value.old,
      [`price-info--currency-${value.currency}`]: true
    }"
  >
    <div
      class="price-info__old"
      v-if="value.old"
    >
      <span class="price-info__old-text">{{ $t('price.instead_of') }}</span>
      <span class="price-info__old-value">
        <span class="price-info__old-value-euro">{{ splitOldValue[0] }}{{ priceDot }}</span>
        <span class="price-info__old-value-cent">{{ splitOldValue[1] }}</span>
        <span class="price-info__currency">{{ value.currency }}</span>
      </span>
    </div>

    <div class="price-info__current">
      <span class="price-info__value">
        <span class="price-info__value-euro">{{ splitCurrentValue[0] }}{{ priceDot }}</span>
        <span class="price-info__value-cent">{{ splitCurrentValue[1] }}</span>
      </span>
      <span class="price-info__currency">
        {{ value.currency }}
      </span>
      <sup
        v-if="showStar"
        class="price-info__asterisk"
      >
        *
      </sup>
      <span
        class="price-info__savedPercentage"
        v-if="showSavedPercentage && value.savedPercentage"
      >
        -{{ value.savedPercentage }}%
      </span>
    </div>
    <div
      class="price-info__info"
      :class="{
        'price-info__info--inline': foodSpecificationLink
      }"
    >
      <a
        v-if="foodSpecificationLink"
        class="price-info__food-specification-link"
        :href="foodSpecificationLink"
        v-html="$t('detail.product.characteristics')"
      />
      <div class="price-info__unit">
        <span class="price-info__unit-text">
          {{ getUnitText() }}
        </span>
        <span
          v-if="value.unitPrice"
          class="price-info__unit-price"
        >
          ({{ value.unitPrice }}<template v-if="value.unit">/{{ value.unit }}</template>)
        </span>
      </div>
    </div>
    <div class="price-info__tax-wrapper">
      <div
        v-if="showTax"
        class="price-info__tax"
        v-html="$t(showShippingIncluded ? messageKey.deliveryInclShip : messageKey.deliveryInclVat, [shippingInfoUrl])"
      />
      <div
        v-if="articleNumber.length > 0"
        class="price-info__tax--article-number"
      >
        {{ $t('checkout.basket.position.productNr') }} {{ articleNumber }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type Price from '../../../bps/types/Price';

  @Options({
    name: 'price-info'
  })
  export default class PriceInfo extends Vue {
    @Prop({required: true})
    value: Price;

    @Prop({type: Boolean, default: false})
    showStar: boolean;

    @Prop({type: Boolean, default: false})
    showTax: boolean;

    @Prop({type: Boolean, default: false})
    showShippingIncluded: boolean;

    @Prop({type: Boolean, default: false})
    showSavedPercentage: boolean;

    @Prop()
    shippingInfoUrl: string;

    @Prop()
    foodSpecificationLink: string;

    @Prop({type: String, default: ''})
    articleNumber: string;

    @Prop({type: Boolean, default: false})
    showVolumeUnitText: boolean;

    messageKey: {[key: string]: any} = {
      deliveryInclShip: 'price.incl_vat_incl_ship',
      deliveryInclVat: 'price.incl_vat',
    };

    mounted() {
      if (window.__productConfig && window.__productConfig.messageKey) {
        this.messageKey = window.__productConfig.messageKey;
      }
    }

    getUnitText() {
      let result = this.showVolumeUnitText ? `${this.$t(`product.price.volumeUnit.${this.value.volumeUnit}`)} ` : '';
      if (this.value.unitPrice) {
        if (this.value.packageSize) {
          result += this.value.packageSize
            .replace('.', ',')
            .replace('l', ' L');
        }
      }
      return result;
    }

    get priceDot() {
      return this.value.currency.toUpperCase() === 'CHF' ? '.' : ',';
    }

    get splitCurrentValue() {
      if (this.value.currency.toUpperCase() === 'CHF') {
        return this.value.currentValue.replace(this.value.currency, '').split(/[:.]/);
      }
      return this.value.currentValue.split(/[:,]/);
    }

    get splitOldValue() {
      if (this.value.currency.toUpperCase() === 'CHF') {
        return this.value.old.replace(this.value.currency, '').split(/[:.]/);
      }
      return this.value.old.replace(this.value.currency, '').split(/[:,]/);
    }
  }
</script>
