const iViewTemplateTransforms: Record<string, string> = {
  'plistvertical_transparent': 'plistverticalvinos_transparent',
  'plistvertical_transparent_webp': 'plistverticalvinos_transparent_webp',
  'plistvertical_transparent_retina': 'plistverticalvinos_transparent_retina',
  'plistvertical_transparent_retina_webp': 'plistverticalvinos_transparent_retina_webp',

  'pdmain': 'pdmainvinos',
  'pdmain_webp': 'pdmainvinos_webp',
  'pdmain_retina': 'pdmainvinos_retina',
  'pdmain_retina_webp': 'pdmainvinos_retina_webp',
};

function transformIViewTemplates(input: string, transformsMap: Record<string, string> = iViewTemplateTransforms) {
  let output: string;
  for (let key in transformsMap) {
    output = input.replace(`/${key}/`, `/${transformsMap[key]}/`);
    if (output !== input) {
      return output;
    }
  }

  return input;
}

export {
  transformIViewTemplates
}

