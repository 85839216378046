import JsComponent from '../jsComponent/jsComponent.js';
import pigeon from '../../utilities/js/pigeon/pigeon.js';

class LocalisationButton extends JsComponent {
  constructor(mainElement, name = 'localisation-button') {
    super(mainElement, name);
    mainElement.addEventListener('click', () => {
      pigeon.publish('localisationSwitch:open');
    });
  }
}

export default LocalisationButton;
