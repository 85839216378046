import {productBoxWideSettings, ProductBoxWideSettings} from '../../../bps/components/productBoxWide/productBoxWideSettings';

productBoxWideSettings.flags = {
  whitelistImage: ['free', 'savings', 'new', 'newVintage', 'award', 'bio'],
  whitelistTag: ['vegan'],
  whitelistConversion: [],
};
productBoxWideSettings.rating.type = 'text';
productBoxWideSettings.readMoreLink = true;
productBoxWideSettings.personalisationTop = true;

export {productBoxWideSettings, ProductBoxWideSettings};
