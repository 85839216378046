import JsComponent from '../../../bps/components/jsComponent/jsComponent.js';
import debounce from 'lodash/debounce';
import pigeon from '../../../bps/utilities/js/pigeon/pigeon';

class Header extends JsComponent {
  constructor(mainElement, name = 'header') {
    super(mainElement, name);
    this.createClassNames(
      '__wrapper',
      '__sticky',
      '__sticky--active',
      '__search-icon',
      '--search-active'
    );

    this.stickyOffset = 80;
    this.isScrollHandlerActive = true;
    this.isStickyEnabled = !this.checkStickyBlocked();
    this.isStickyActive = false;
    this.isCartLayerActive = false;
    this.previousScrollPosition = window.scrollY;
    this.headerHeight = Math.round(document.querySelector('.header').getBoundingClientRect().height);

    this.stickyEnabled = this.mainElement.dataset.stickyEnabled ? JSON.parse(this.mainElement.dataset.stickyEnabled) : true;

    if (this.stickyEnabled) {
      this.layoutElement = document.querySelector('.page-main');
      this.stickyElement = this.mainElement.querySelector(`.${this.classNames.sticky}`);
      this.stickyIsActive = false;
      this.windowResizeHandler();
      this.headerStickyToggler();
      this.windowResizeHandlerDobunced = debounce(this.windowResizeHandler.bind(this), 100);
      window.addEventListener('resize', this.windowResizeHandlerDobunced);
    }

    this.bindEvents();
    this.isScrollHandlerActive = true;
    this.setHeaderHeight();
  }

  bindEvents() {
    if (this.stickyEnabled) {
      const scrollHandler = this.windowScrollHandler.bind(this);
      window.addEventListener('scroll', debounce(scrollHandler, 25, {
        maxWait: 50
      }));
    }

    this.mainElement.querySelector(`.${this.classNames.searchIcon}`)
      .addEventListener('click', this.toggleSearch.bind(this));

    pigeon.subscribe('header:setHeaderHeight', () => {
      this.setHeaderHeight();
    });

    pigeon.subscribe('addToCartLayer:open', () => {
      this.isCartLayerActive = true;
      this.windowScrollHandler();
    });

    pigeon.subscribe('addToCartLayer:close', () => {
      setTimeout(() => {
        this.isCartLayerActive = false;
        this.windowScrollHandler();
      }, 200);
    });
  }

  windowResizeHandler() {
    if (this.stickyElement) {
      this.setHeaderHeight();
    }
  }

  setHeaderHeight() {
    const stickyHeight = Math.round(this.stickyElement.getBoundingClientRect().height);
    this.pageMainElementTop = stickyHeight;

    const newHeaderHeight = document.querySelector('.header').getBoundingClientRect().height;
    if (newHeaderHeight > 0) {
      this.headerHeight = newHeaderHeight;
    }

    if (this.stickyIsActive) {
      this.layoutElement.style.paddingTop = this.headerHeight + 'px';
    }

    this.layoutElement.style.setProperty('--header-height', `${this.headerHeight}px`);
    this.layoutElement.style.setProperty('--header-sticky-height', `${stickyHeight}px`);
  }

  headerStickyToggler() {
    const currentScrollPosition = window.pageYOffset;
    document.body.classList.remove('page--scroll-down');
    document.body.classList.remove('page--scroll-up');
    if (currentScrollPosition > 0 && currentScrollPosition < this.previousScrollPosition) {
      document.body.classList.add('page--scroll-up');
    } else if (currentScrollPosition > 0 && currentScrollPosition >= this.previousScrollPosition){
      document.body.classList.add('page--scroll-down');
    }
    this.previousScrollPosition = currentScrollPosition;

    if (currentScrollPosition > this.pageMainElementTop && !this.stickyIsActive) {
      this.activateStickyPos();
      this.setHeaderHeight();
    } else if (currentScrollPosition <= this.pageMainElementTop && this.stickyIsActive) {
      this.deactivateStickyPos();
      this.setHeaderHeight();
    }
  }

  activateStickyPos() {
    this.stickyIsActive = true;
    this.stickyElement.classList.add(this.classNames.stickyActive);
    this.layoutElement.style.paddingTop = this.pageMainElementTop + 'px';
    this.layoutElement.classList.add('page-main--sticky-active');
    document.body.classList.add('page--sticky-header');
  }

  deactivateStickyPos() {
    this.stickyIsActive = false;
    this.stickyElement.classList.remove(this.classNames.stickyActive);
    this.layoutElement.style.paddingTop = '0';
    this.layoutElement.classList.remove('page-main--sticky-active');
    document.body.classList.remove('page--scroll-up');
    document.body.classList.remove('page--scroll-down');
    document.body.classList.remove('page--sticky-header');
  }

  checkStickyBlocked() {
    const stickyBlockSelectors = ['.product-detailed-page'];
    for (let ndx = 0; ndx < stickyBlockSelectors.length; ndx++) {
      if (document.querySelector(stickyBlockSelectors[ndx]) !== null) {
        return true;
      }
    }
    return false;
  }

  windowScrollHandler() {
    this.headerStickyToggler();
    if (this.isSearchActive() && this.isScrollHandlerActive) {
      if (this.isSuggestActive()) {
        this.mainElement.querySelector('.search-autosuggest__input input').blur();
      } else {
        this.toggleSearch();
      }
    }

    if (this.isScrollHandlerActive) {
      // Toggle Search
      if (this.isSearchActive()) {
        if (this.isSuggestActive()) {
          this.mainElement.querySelector('.search-autosuggest__input input').blur();
        } else {
          this.toggleSearch();
        }
      }

      // Toggle stickyCart
      if (this.isStickyEnabled) {
        const windowScrollPos = window.pageYOffset;
        if (windowScrollPos > this.stickyOffset && !this.isStickyActive && this.isCartLayerActive) {
          this.isStickyActive = true;
          this.mainElement.classList.add('header--sticky');
        } else if ((windowScrollPos <= this.stickyOffset && this.isStickyActive) || !this.isCartLayerActive) {
          this.isStickyActive = false;
          this.mainElement.classList.remove('header--sticky');
        }
      }
    }
  }

  toggleSearch() {
    this.mainElement.classList.toggle(this.classNames.searchActive);
    if (this.isSearchActive()) {

      // In some cases mobile browsers scroll to the focused input field,
      // which would close our search. Deactivate our behavior for a short time to prevent this.
      this.isScrollHandlerActive = false;
      setTimeout(() => {
        this.isScrollHandlerActive = true;
      }, 1000);

      // Put focus on search field
      this.mainElement.querySelector('.input__field').focus();
    }
  }

  isSearchActive() {
    return this.mainElement.classList.contains(this.classNames.searchActive);
  }

  isSuggestActive() {
    return !!this.mainElement.querySelector('.search-autosuggest__suggestions-flyout');
  }
}

export default Header;
