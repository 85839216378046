import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "localisation-switch-layer",
  method: "get",
  action: ""
}
const _hoisted_2 = { class: "localisation-switch-layer__head" }
const _hoisted_3 = { class: "localisation-switch-layer__content" }
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_vue = _resolveComponent("select-vue")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_openBlock(), _createBlock(_Transition, { name: "localisation-switch-layer-animate-top" }, {
    default: _withCtx(() => [
      (_ctx.isOpen)
        ? _withDirectives((_openBlock(), _createElementBlock("form", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.$t('localisation.layer.headline')) + " ", 1 /* TEXT */),
              _createElementVNode("div", {
                class: "localisation-switch-layer__close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
              })
            ]),
            _createTextVNode(),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_select_vue, {
                class: "localisation-switch-layer__country-select",
                modelValue: _ctx.model.country,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.country) = $event)),
                options: _ctx.getCountryOptions(),
                "initial-value": _ctx.selectedCountry
              }, null, 8 /* PROPS */, ["modelValue", "options", "initial-value"]),
              _createTextVNode(),
              (_ctx.getLanguageOptions().length > 1)
                ? (_openBlock(), _createBlock(_component_select_vue, {
                    key: 0,
                    class: "localisation-switch-layer__language-select",
                    modelValue: _ctx.model.language,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.language) = $event)),
                    options: _ctx.getLanguageOptions(),
                    "initial-value": _ctx.model.language
                  }, null, 8 /* PROPS */, ["modelValue", "options", "initial-value"]))
                : _createCommentVNode("v-if", true),
              _createTextVNode(),
              _createElementVNode("input", {
                type: "hidden",
                name: "geo",
                value: _ctx.getSubmitValue(_ctx.model.country)
              }, null, 8 /* PROPS */, _hoisted_4),
              _createTextVNode(),
              _createElementVNode("input", {
                type: "hidden",
                name: "selectedCountry",
                value: _ctx.model.country
              }, null, 8 /* PROPS */, _hoisted_5),
              _createTextVNode(),
              (_ctx.getLanguageOptions().length > 1)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 1,
                    type: "hidden",
                    name: "lang",
                    value: _ctx.getSubmitValue(_ctx.model.language)
                  }, null, 8 /* PROPS */, _hoisted_6))
                : _createCommentVNode("v-if", true),
              _createTextVNode(),
              _createElementVNode("button", {
                class: _normalizeClass(["localisation-switch-layer__btn | button button--primary", {'button--loading': _ctx.isLoading}]),
                type: "submit"
              }, _toDisplayString(_ctx.$t('localisation.layer.button.save')), 3 /* TEXT, CLASS */)
            ])
          ])), [
            [_directive_outside_click, _ctx.close, true]
          ])
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}