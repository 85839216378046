import {ValidationFieldConfig, ValidationValues} from  '../../../../bps/utilities/js/validation/ValidationFieldConfig';
import {maxLength} from '@vuelidate/validators';

ValidationFieldConfig.name.max = maxLength(49);
ValidationFieldConfig.careOf.max = maxLength(50);
ValidationFieldConfig.street.max = maxLength(90);
ValidationFieldConfig.city.max = maxLength(30);
ValidationFieldConfig.cityGer.max = maxLength(30);
ValidationFieldConfig.debitName.max = maxLength(50);
delete(ValidationFieldConfig.birthdate.maxAge);

type ValidationFieldConfigKey = keyof typeof ValidationFieldConfig;
export {ValidationFieldConfig, ValidationFieldConfigKey, ValidationValues}
