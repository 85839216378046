import './main.scss';
import './staticImages/staticImages';

import '../bps/components/envInjection';
import './components/componentsInjection';
import {JsComponents, VueComponents, VueGlobalComponents} from './components/components';

import App from '../bps/App.js';
window.__APP = new App(JsComponents, VueComponents, VueGlobalComponents);

