import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "product-box__segment-main" }
const _hoisted_2 = {
  key: 1,
  class: "product-box__tags-wrapper"
}
const _hoisted_3 = {
  key: 2,
  class: "product-box__price-wrapper product-box__price-wrapper--no-price"
}
const _hoisted_4 = { class: "product-box__buttons" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 3,
  class: "product-box__conversion"
}
const _hoisted_7 = { class: "product-box__info-wrapper" }
const _hoisted_8 = {
  key: 0,
  class: "product-box__bulk-price"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 0,
  class: "product-box__price-wrapper"
}
const _hoisted_11 = { class: "product-box__buttons-wrapper" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = ["href", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ratings = _resolveComponent("ratings")!
  const _component_tags = _resolveComponent("tags")!
  const _component_flags = _resolveComponent("flags")!
  const _component_availability_info = _resolveComponent("availability-info")!
  const _component_winemaker_price = _resolveComponent("winemaker-price")!
  const _component_price_info = _resolveComponent("price-info")!
  const _component_product_qty_and_cart_btn = _resolveComponent("product-qty-and-cart-btn")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.productBoxData.ratings > _ctx.minimumRating && _ctx.isBoxTypeVertical)
      ? (_openBlock(), _createBlock(_component_ratings, {
          key: 0,
          class: "product-box__ratings",
          rating: _ctx.productBoxData.ratings,
          "ratings-counter": _ctx.productBoxData.ratingsCounter,
          type: _ctx.settings.rating.type
        }, null, 8 /* PROPS */, ["rating", "ratings-counter", "type"]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    ((_ctx.productBoxData.flags || _ctx.productBoxData.tags) && _ctx.isBoxTypeVertical)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isBoxTypeVertical)
            ? (_openBlock(), _createBlock(_component_tags, {
                key: 0,
                class: _normalizeClass(["product-box__tags", {
          'product-box__tags--more': _ctx.productBoxData.tags.length > 2,
          'product-box__tags--has-award': _ctx.productBoxData.flags.awardImageUrls && _ctx.productBoxData.flags.awardImageUrls.length
        }]),
                tags: _ctx.tags,
                onQuantityChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('quantityChange', $event)))
              }, null, 8 /* PROPS */, ["class", "tags"]))
            : _createCommentVNode("v-if", true),
          _createTextVNode(),
          _createVNode(_component_flags, {
            flags: _ctx.productBoxData.flags,
            limit: _ctx.isBoxTypeVertical ? 4 : 3,
            "award-limit": _ctx.isBoxTypeVertical ? 2 : 1,
            "white-list": _ctx.settings.flags.whitelistTag
          }, null, 8 /* PROPS */, ["flags", "limit", "award-limit", "white-list"])
        ]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    (_ctx.productBoxData.zeroPriceItem || _ctx.productBoxData.recurringOrderFollowUpPacket)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              class: "product-box__status product-box__status--unavailable",
              href: _ctx.productBoxData.url
            }, _toDisplayString(_ctx.$t('availability.no-price')), 9 /* TEXT, PROPS */, _hoisted_5)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.bulkPrice)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: "product-box__bulk-price-info",
                    innerHTML: _ctx.bulkPrice,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('quantityChange', _ctx.bulkPriceQuantity)))
                  }, null, 8 /* PROPS */, _hoisted_9)
                ]))
              : _createCommentVNode("v-if", true),
            _createTextVNode(),
            (_ctx.showAvailability)
              ? (_openBlock(), _createBlock(_component_availability_info, {
                  key: 1,
                  class: "product-box__availability",
                  availability: _ctx.productBoxData.availability
                }, null, 8 /* PROPS */, ["availability"]))
              : _createCommentVNode("v-if", true),
            _createTextVNode(),
            (_ctx.productBoxData.winemakerPrice)
              ? (_openBlock(), _createBlock(_component_winemaker_price, { key: 2 }))
              : _createCommentVNode("v-if", true)
          ]),
          _createTextVNode(),
          (_ctx.productBoxData.price || _ctx.productBoxData.soldoutRelation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (!_ctx.hidePrice)
                  ? (_openBlock(), _createBlock(_component_price_info, {
                      key: 0,
                      class: "product-box__price",
                      value: _ctx.productBoxData.price,
                      alcohol: _ctx.productBoxData.alcohol,
                      "show-star": true
                    }, null, 8 /* PROPS */, ["value", "alcohol"]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true),
          _createTextVNode(),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_product_qty_and_cart_btn, {
              "product-data": _ctx.productBoxData,
              "product-link": _ctx.productLink,
              state: _ctx.state,
              "add-successor-active": _ctx.addSuccessorActive,
              onQuantityChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('quantityChange', $event))),
              onAddToBasket: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('addToBasket', $event))),
              onOnRequestClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('onRequestClicked', $event)))
            }, null, 8 /* PROPS */, ["product-data", "product-link", "state", "add-successor-active"]),
            _createTextVNode(),
            (_ctx.productBoxData.inBasket)
              ? _withDirectives((_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "product-box__status product-box__status--basket",
                  href: _ctx.basketUrl
                }, null, 8 /* PROPS */, _hoisted_12)), [
                  [_directive_t, 'ads.ads_top_info.article_in_basket']
                ])
              : _createCommentVNode("v-if", true),
            _createTextVNode(),
            (_ctx.productBoxData.successorInBasket && !_ctx.productBoxData.inBasket && _ctx.addSuccessorActive)
              ? _withDirectives((_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "product-box__status product-box__status--basket product-box__status--successor-basket",
                  href: _ctx.basketUrl
                }, null, 8 /* PROPS */, _hoisted_13)), [
                  [_directive_t, 'ads.ads_top_info.successor_in_basket']
                ])
              : _createCommentVNode("v-if", true),
            _createTextVNode(),
            (_ctx.productBoxData.showFoodSpecificationLink && !_ctx.hideCounterButton)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  href: _ctx.foodSpecificationLink,
                  class: "product-box__link",
                  innerHTML: _ctx.$t('detail.product.characteristics')
                }, null, 8 /* PROPS */, _hoisted_14))
              : _createCommentVNode("v-if", true)
          ])
        ]))
  ]))
}