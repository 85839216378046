<template>
  <div class="product-box__segment-header__wrapper">
    <component
      :is="personalizedBannerLink ? 'a' : 'div'"
      class="product-box__personalization-banner-wrapper"
      v-if="productBoxData.personalPreferenceMessageKey && !showReview"
      :href="personalizedBannerLink"
    >
      <div
        class="product-box__personalization-banner personalization-banner"
        v-html="$t(productBoxData.personalPreferenceMessageKey)"
      />
    </component>
    <component
      :is="productLink ? 'a' : 'div'"
      :href="productLink"
      class="product-box__segment-header"
    >
      <div class="product-box__header-text">
        <span class="product-box__name">
          <span v-if="numberOfBottlesInSet">
            {{ numberOfBottlesInSet }} x
          </span>
          {{ productBoxData.name }}
        </span>
        <span
          class="product-box__short-description"
          v-if="productBoxData.shortDescription"
          v-html="productBoxData.shortDescription"
        />
      </div>

      <span
        v-if="!productBoxData.zeroPriceItem"
        class="product-box__button-wishlist"
        :class="{
          'product-box__button-wishlist--filled': isWishlistEnabled && productBoxData.inWishlist,
          'product-box__button-wishlist--remove': !isWishlistEnabled,
          'product-box__button-wishlist--loading': state === 'loadingWishlist',
        }"
        @click.prevent="$emit('wishlistClicked')"
      />
    </component>
  </div>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';

  @Options({
    name: 'product-box-segment-header'
  })
  export default class ProductBoxPartHead extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    state: string;
    @Prop()
    numberOfBottlesInSet: number;
    @Prop()
    productLink: string;
    @Prop({default: false})
    showReview: boolean;
    @Prop({default: true})
    isWishlistEnabled: boolean;

    get personalizedBannerLink() {
      return this.$t(`productbox.link.${this.productBoxData.personalPreferenceMessageKey}`, [this.productBoxData.url]);
    }
  }
</script>
