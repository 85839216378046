import {productBoxSettings, ProductBoxSettings} from '../../../bps/components/productBox/productBoxSettings';

productBoxSettings.showArea = true;
productBoxSettings.flags.whitelist = ['free', 'savings', 'new', 'newVintage', 'award', 'bio'];
productBoxSettings.flags.whitelistTag = ['vegan'];
productBoxSettings.flags.whitelistInSet = ['free', 'new', 'award', 'bio'];
productBoxSettings.referredByHash = '';
productBoxSettings.slider = {
  mobile: {
    offset: 0.25,
    centeredSlides: true
  },
  tablet: {
    offset: 0,
    centeredSlides: false
  },
  desktop: {
    offset: 0,
    centeredSlides: false
  }
};

export {productBoxSettings, ProductBoxSettings};
