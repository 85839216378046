import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "product-box__segment-image" }
const _hoisted_2 = {
  key: 0,
  class: "product-box__rating-container"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "product-box__bottle-amount" }
const _hoisted_5 = {
  key: 0,
  class: "product-box__product-flags"
}
const _hoisted_6 = { class: "product-box__image-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ratings = _resolveComponent("ratings")!
  const _component_flags = _resolveComponent("flags")!
  const _component_image_element = _resolveComponent("image-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isBoxTypeHorizontal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.productBoxData.numberOfBottlesInSet >= 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('volumeUnit.numberOfBottlesInSet', [_ctx.productBoxData.numberOfBottlesInSet])), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          _createTextVNode(),
          _createElementVNode("div", null, [
            (_ctx.productBoxData.ratings > _ctx.minimumRating)
              ? (_openBlock(), _createBlock(_component_ratings, {
                  key: 0,
                  class: "product-box__ratings",
                  rating: _ctx.productBoxData.ratings,
                  "ratings-counter": _ctx.productBoxData.ratingsCounter,
                  type: _ctx.settings.rating.type
                }, null, 8 /* PROPS */, ["rating", "ratings-counter", "type"]))
              : _createCommentVNode("v-if", true)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productBoxData.valid ? 'a' : 'div'), {
      class: "product-box__image-container",
      href: _ctx.productLink,
      target: _ctx.productLink && _ctx.target ? _ctx.target : null,
      "aria-label": "product image"
    }, {
      default: _withCtx(() => [
        (_ctx.productBoxData.flags)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_flags, {
                class: _normalizeClass({'product-box__product-flags--hidden': _ctx.productBoxData.inBasket}),
                flags: _ctx.productBoxData.flags,
                limit: _ctx.isBoxTypeVertical ? 4 : 2,
                "award-limit": _ctx.isBoxTypeVertical ? 2 : 1,
                "white-list": _ctx.settings.flags.whitelist
              }, null, 8 /* PROPS */, ["class", "flags", "limit", "award-limit", "white-list"])
            ]))
          : _createCommentVNode("v-if", true),
        _createTextVNode(),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_image_element, {
            class: "product-box__image",
            src: _ctx.imgSrc,
            alt: _ctx.productBoxData.images[0].alt,
            webp: true
          }, null, 8 /* PROPS */, ["src", "alt"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["href", "target"]))
  ]))
}