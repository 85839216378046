import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "product-box-wide__segment-image" }
const _hoisted_2 = {
  key: 0,
  class: "product-box-wide__product-flags"
}
const _hoisted_3 = { class: "lazy-load-image product-box-wide__image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flags = _resolveComponent("flags")!
  const _component_image_element = _resolveComponent("image-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.productBoxData.flags)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_flags, {
            flags: _ctx.productBoxData.flags,
            limit: 4,
            "award-limit": 2,
            "white-list": _ctx.settings.flags.whitelistImage
          }, null, 8 /* PROPS */, ["flags", "white-list"])
        ]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productBoxData.linked ? 'a' : 'div'), {
      href: _ctx.productBoxData.linked ? _ctx.productBoxData.url : null,
      class: _normalizeClass(["product-box-wide__image-wrapper", {
        'product-box-wide__image-wrapper--bottle': _ctx.isBottle,
        'product-box-wide__image-wrapper--vertical': _ctx.isBoxTypeVertical
      }])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_image_element, {
            "img-class": "lazy-load-image__image",
            src: _ctx.imgSrc,
            title: _ctx.productBoxData.images[1].title,
            alt: _ctx.productBoxData.images[1].alt,
            webp: true
          }, null, 8 /* PROPS */, ["src", "title", "alt"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["href", "class"]))
  ]))
}