<template>
  <div
    class="select"
    :class="{
      'select--active': active,
      'select--hide-placeholder': hidePlaceholder(),
      'select--no-label': !label,
      'select--selected': value !== '',
      'select--disabled': disabled,
    }"
  >
    <label
      v-if="label"
      class="select__label"
      :class="{
        'select__label--required': required,
        'select__label--as-placeholder': value === '',
        'select__label--only-mobile': labelType === 'only-mobile'
      }"
    >
      {{ inputLabel }}
    </label>

    <div
      class="select__wrapper"
      :class="{
        'select__wrapper--error': !!error,
        'select__wrapper--disabled': disabled,
        'select__wrapper--custom': useCustomSelect && !(useNativeOnMobile && isMobile),
        'select__wrapper--active': active
      }"
      @click.stop="selectWrapperClickHandler"
    >
      <div
        class="select__selected-value"
        :class="{
          'select__selected-value--align-right': alignRight
        }"
      >
        <span
          v-if="selectedValueIcon"
          v-html="selectedValueIcon"
          class="select__selected-value-image"
        />
        <span>{{ selectedValueLabel }}</span>
      </div>

      <!-- CUSTOM SELECT DROPDOWN -->
      <transition
        name="vue-animation--move"
        :duration="{ enter: 200, leave: 50 }"
      >
        <div
          v-if="customDropdownIsVisible && (useCustomSelect && !(useNativeOnMobile && isMobile) && customDropdownIsVisible)"
          class="select__custom-dropdown"
          :class="{
            'select__custom-dropdown--expand-top': dropdownExpandTop,
            'select__custom-dropdown--visible': customDropdownIsVisible,
          }"
        >
          <div
            class="select__custom-options-wrapper"
            ref="customOptionsWrapper"
          >
            <div
              class="select__custom-option"
              :class="{
                'select__custom-option--align-right': alignRight,
                'select__custom-option--selected': option.value === value
              }"
              v-for="(option, index) in options"
              v-show="!option.disabled"
              :key="option.value"
              @click.stop="customOptionClickHandler(index)"
            >
              <span>{{ option.label }}</span>
            </div>
          </div>
        </div>
      </transition>

      <!-- NATIVE SELECT -->
      <select
        v-show="(useNativeOnMobile && isMobile) || !useCustomSelect"
        class="select__field"
        ref="nativeselect"
        :name="innerName"
        :disabled="disabled"
        v-model="value"
        :id="id || null"
        @blur="blurHandler"
        @focus="focusHandler"
        @change="changeHandler"
        :autocomplete="autocomplete"
      >
        <option
          v-for="(option, index) in options"
          :key="index"
          :disabled="option.disabled"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>

    <transition name="input-error--animation">
      <span
        class="input__error | input-error"
        v-if="!!error"
        v-html="error"
      />
    </transition>
  </div>
</template>

<script>
  import breakpoints from '../../variables/js/breakpoints.js';
  import pigeon from '../../utilities/js/pigeon/pigeon';

  const Select = {
    name: 'select-vue',
    emits: ['change', 'update:modelValue', 'input', 'blur'],
    props: {
      options: {
        type: Array,
        default() {
          return [];
        }
      },
      labelType: String,
      alignRight: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      name: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      useCustomSelect: {
        type: Boolean,
        default: true
      },
      useNativeOnMobile: {
        type: Boolean,
        default: true
      },
      initialValue: {
        default: null
      },
      autocomplete: {
        type: String
      },
      hideLabelPlaceholder: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        value: null,
        selectedValueLabel: null,
        selectedValueIcon: null,
        customDropdownIsVisible: false,
        customDropdownMaxHeight: 0,
        isMobile: window.innerWidth <= breakpoints.laptop,
        active: false,
        dropdownExpandTop: false,
        innerName: '',
      };
    },
    created() {
      window.addEventListener('breakpointChange', this.windowBreakpointChangeHandler.bind(this));
      pigeon.subscribe('select:activate', this.globalOnSelectActivate.bind(this));
    },
    mounted() {
      this.innerName = this.name;
      if (this.$refs.customOptionsWrapper) {
        this.customDropdownMaxHeight = 300;
      }

      this.setInitialValue();
      this.setNativeSelectValueGetter();
    },
    methods: {
      setInitialValue() {
        const value = this.initialValue ? this.initialValue : this.options[0].value;
        this.setSelectedOptionData(value, false);
      },
      setSelectedOptionData(value, emitChange = true) {
        let selectedOption = this.options.find(option => option.value === value);
        selectedOption = selectedOption ? selectedOption : this.options[0];

        this.selectedValueLabel = selectedOption.label;
        this.selectedValueIcon = selectedOption.icon;

        if (this.value !== selectedOption.value) {
          this.value = selectedOption.value;
          this.$emit('update:modelValue', this.value);
          this.$emit('input', this.value);
          if (emitChange) {
            this.$emit('change', {value: this.value, selectedLabel: this.selectedValueLabel});
            this.blurHandler();
          }
        }
      },
      getValue() {
        return this.value;
      },
      setValue(value) {
        this.setSelectedOptionData(value);
      },
      reset() {
        this.setInitialValue();
      },
      clear() {
        const value = this.options[0].value;
        this.setSelectedOptionData(value);
      },
      blurHandler() {
        // this.active = false; // no status change when using native select
        this.$emit('blur');
        this.$refs.nativeselect?.blur();
      },
      focusHandler() {
        // this.active = true; // no status change when using native select
      },
      changeHandler() {
        this.$emit('change', {value: this.value, selectedLabel: this.selectedValueLabel});
        this.$emit('update:modelValue', this.value);
        this.$emit('input', this.value);
        this.$nextTick(() => {
          this.setSelectedOptionData(this.value);
          this.blurHandler();
        });
      },
      setNativeSelectValueGetter() {
        let getValue = this.getValue;

        Object.defineProperty(
          this.$refs.nativeselect,
          'value',
          {
            get() {
              return getValue();
            }
          }
        );
      },
      selectWrapperClickHandler() {
        if (this.useCustomSelect && !(this.useNativeOnMobile && this.isMobile) && !this.disabled) {
          if (!this.customDropdownIsVisible) {
            this.showCustomDropdown();
          } else {
            this.hideCustomDropdown();
          }
        }
      },
      showCustomDropdown() {
        pigeon.publish('select:activate', this);
        this.dropdownExpandTop = false;

        this.$nextTick(() => {
          this.customDropdownIsVisible = true;
          this.active = true;
        })
        setTimeout(() => {
          document.body.addEventListener('click', this.hideCustomDropdown);
        })
      },
      hideCustomDropdown() {
        document.body.removeEventListener('click', this.hideCustomDropdown);
        this.customDropdownIsVisible = false;
        this.active = false;
      },
      checkIfDropdownShouldExpandTop() {
        const selectRect = this.$el.getBoundingClientRect();
        return window.innerHeight - selectRect.bottom < this.customDropdownMaxHeight;
      },
      customOptionClickHandler(optionIndex) {
        this.setSelectedOptionData(this.options[optionIndex].value);
        this.hideCustomDropdown();
      },
      windowBreakpointChangeHandler() {
        this.isMobile = window.innerWidth <= breakpoints.laptop;
      },
      globalOnSelectActivate() {
        this.hideCustomDropdown();
      },
      hidePlaceholder() {
        return this.hideLabelPlaceholder && this.value !== '';
      }
    },
    computed: {
      inputLabel() {
        return this.label + (this.required ? this.$t('form.mandatory_asterisk') : '');
      }
    },
    watch: {
      options() {
        this.setSelectedOptionData(this.value);
      }
    }
  };

  export default Select;
</script>
