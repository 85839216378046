import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "product-box__segment-header__wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "product-box__header-text" }
const _hoisted_4 = { class: "product-box__name" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.productBoxData.personalPreferenceMessageKey && !_ctx.showReview)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.personalizedBannerLink ? 'a' : 'div'), {
          key: 0,
          class: "product-box__personalization-banner-wrapper",
          href: _ctx.personalizedBannerLink
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "product-box__personalization-banner personalization-banner",
              innerHTML: _ctx.$t(_ctx.productBoxData.personalPreferenceMessageKey)
            }, null, 8 /* PROPS */, _hoisted_2)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["href"]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productLink ? 'a' : 'div'), {
      href: _ctx.productLink,
      class: "product-box__segment-header"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            (_ctx.numberOfBottlesInSet)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.numberOfBottlesInSet) + " x\n          ", 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(_ctx.productBoxData.name), 1 /* TEXT */)
          ]),
          _createTextVNode(),
          (_ctx.productBoxData.shortDescription)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "product-box__short-description",
                innerHTML: _ctx.productBoxData.shortDescription
              }, null, 8 /* PROPS */, _hoisted_6))
            : _createCommentVNode("v-if", true)
        ]),
        _createTextVNode(),
        (!_ctx.productBoxData.zeroPriceItem)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["product-box__button-wishlist", {
          'product-box__button-wishlist--filled': _ctx.isWishlistEnabled && _ctx.productBoxData.inWishlist,
          'product-box__button-wishlist--remove': !_ctx.isWishlistEnabled,
          'product-box__button-wishlist--loading': _ctx.state === 'loadingWishlist',
        }]),
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('wishlistClicked')), ["prevent"]))
            }, null, 2 /* CLASS */))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["href"]))
  ]))
}